import { createStyles } from "@mantine/emotion";

import { rooterMediaQuery } from "_styles/theme/mediaQuery";

export default createStyles ( ( theme, props ) => ( {
	container: {
		height          : "100vh",
		borderRight     : `solid 0.75px ${ theme.other.colors.border.primary }`,
		position        : "fixed",
		zIndex          : "1112",
		top             : "0",
		left            : "0",
		overflowX       : "hidden",
		transition      : "width 0.2s",
		marginTop       : "70px",
		backgroundColor : theme.other.colors.background.secondary,
		width           : props?.isSidebarMenuOpen ? "250px" : "6rem",

		msOverFlowStytle       : "none", /* Internet Explorer 10+ */
		scrollBarWidth         : "none", /* Firefox */
		"&::-webkit-scrollbar" : {
			display: "none" /* Safari and Chrome */
		},

		[rooterMediaQuery.tablet]: {
			width: props?.isSidebarMenuOpen ? "225px" : "5rem"
		}
	},
	contentContainer: {
		position   : "relative",
		zIndex     : "1112",
		background : theme.other.colors.background.secondary,
		minHeight  : "calc(100vh - 70px)"
	},
	navMenuContainer: {
		// paddingTop    : "2rem",
		height        : "fit-content",
		borderBottom  : `solid 0.75px ${ theme.other.colors.border.primary }`,
		display       : "flex",
		flexDirection : "column"
		// alignItems    : "center"

	},
	mobile: {
		position    : "relative",
		display     : "flex",
		alignItems  : "center",
		// backgroundColor : theme.other.paletteNew.darkModeDarkBlue50,
		padding     : "1rem 0",
		cursor      : "pointer",
		"&::before" : {
			width                   : "100%",
			content                 : "''",
			display                 : "block",
			top                     : "0",
			height                  : "0.4rem",
			position                : "absolute",
			borderBottomLeftRadius  : "1rem",
			borderBottomRightRadius : "1rem",
			backgroundImage         : "linear-gradient(to bottom, #00c5ff 0%, #0076ff 100%)"
		}
	},
	menuItemContainerActive: {
		position     : "relative",
		display      : "flex",
		alignItems   : "center",
		padding      : "1rem 0",
		cursor       : "pointer",
		marginBottom : theme.other.isMobile ? "0" : "1rem",

		"&::before": {
			width                   : "0.45rem",
			content                 : "''",
			display                 : "block",
			height                  : "95%",
			position                : "absolute",
			borderTopRightRadius    : "1rem",
			borderBottomRightRadius : "1rem",
			backgroundImage         : "linear-gradient(to bottom, #00c5ff 0%, #0076ff 100%)"
		},

		[rooterMediaQuery.mobile]: {
			backgroundColor : theme.other.paletteNew.darkModeDarkBlue50,
			padding         : "1.6rem 0",
			marginBottom    : "unset"
		}
	},
	menuItemContainer: {
		display      : "flex",
		marginBottom : theme.other.isMobile ? "0" : "1rem",
		alignItems   : "center",
		padding      : "1rem 0",
		cursor       : "pointer"
	},
	menuItem: {
		// ...theme.other.svgIcons.size35,
		height     : "2.8rem",
		width      : "2.8rem",
		marginLeft : "1.6rem",
		cursor     : "pointer",

		[rooterMediaQuery.tablet]: {
			height : "2.8rem",
			width  : "2.8rem"
		}
	},
	liveFollowersContainer: {
		display        : "flex",
		alignItems     : "center",
		justifyContent : "center",
		paddingTop     : "2rem",
		flexDirection  : "column"
	},
	liveFollowersContainerExpanded: {
		paddingTop    : props?.isSidebarMenuOpen ? "1.5rem" : "0",
		transition    : "0.2s",
		paddingBottom : "1rem",
		borderBottom  : props?.isSidebarMenuOpen ? `solid 0.75px ${ theme.other.colors.border.primary }` : "none"

	},
	cardTitle: {
		marginBottom : props?.isSidebarMenuOpen ? "1rem" : "0",
		transition   : "0.2s"

	},
	menuName: {
		textOverflow : "ellipsis",
		whiteSpace   : "nowrap",
		marginLeft   : "1.2rem",
		fontSize     : "1.5rem",
		fontWeight   : "500",
		overflow     : "hidden",
		transition   : "0.2s"
	},
	showIconStyle: {
		height : "1rem",
		width  : "1rem"
	},
	/* showMoreButton: {
		padding    : "0.5rem 1rem !important",
		transition : "0.2s",
		opacity    : props?.isSidebarMenuOpen ? "1" : "0"

	}, */
	showMoreButtonText: {
		color    : theme.other.colors.text.secondary,
		fontSize : "12px !important"
	},
	showMoreButton: {
		color     : theme.other.colors.textBtn.disabled.text,
		"&:hover" : {
			background: "none"
		}
	},
	cardContainer: {
		display       : "flex",
		// alignItems : "center",
		flexDirection : "column"
	},
	recommendedContainerExpanded: {
		paddingTop   : props?.isSidebarMenuOpen ? "1.5rem" : "0",
		opacity      : props?.isSidebarMenuOpen ? "1" : "0",
		display      : props?.isSidebarMenuOpen ? "block" : "none",
		borderBottom : props?.isSidebarMenuOpen ? `solid 0.75px ${ theme.other.colors.border.primary }` : "none"
	},
	footerContainer: {
		padding : "2rem",
		display : props?.isSidebarMenuOpen ? "block" : "none"
	},
	footerText: {
		marginBottom : "0.5rem",
		fontSize     : "1.25rem",

		"&:hover": {
			textDecoration: "underline"
		}
	},
	rooterText: {
		fontSize  : "1rem",
		marginTop : "1.5rem"
	},
	overlay: {
		inset           : "0px 0px 0px 0px",
		position        : "fixed",
		display         : props?.isSidebarMenuOpen ? "block" : "none",
		backgroundColor : theme.other.paletteNew.secondaryBlack50
		// zIndex          : "1200"
	},
	loaderContainer: {
		margin     : "1rem",
		display    : "flex",
		alignItems : "center"
	},
	circulatSkeleton: {
		marginRight: theme.other.isMobile ? "1rem" : "0"
	},
	rectangularSekleton: {
		width: "70%"
	},
	popperStyles: {
		width           : "400px",
		height          : "auto",
		borderRadius    : "10px",
		backgroundColor : theme.other.colors.background.primary,
		boxShadow       : theme.other.colors.boxShadow.primary,
		padding         : "2rem",
		left            : "220px !important",
		top             : "-19px !important",
		zIndex          : 1
	},
	arrowIconBtn: {
		all            : "unset",
		width          : "100%",
		display        : "flex",
		alignItems     : "center",
		justifyContent : "center"

	},
	arrowIcon: {
		height : "auto",
		width  : "2rem",
		cursor : "pointer"
	}
} ), { name: "sidebarMenu" } );

