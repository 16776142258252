import { createStyles } from "@mantine/emotion";

export default createStyles ( theme => ( {
	avatarContainer : { flex: 0 },
	avatar          : {
		width  : "4rem",
		height : "4rem"
	},
	img: {
		...theme.other.responsiveImage,
		...theme.other.rooterAvatars.borderRadius
	},
	topSection: {
		display      : "flex",
		alignItems   : "center",
		marginBottom : "0.5rem"
	},
	cardHeader: {
		whiteSpace   : "nowrap",
		textOverflow : "ellipsis",
		overflow     : "hidden",
		fontWeight   : "500 !important",
		// color        : "rgba(0, 0, 0, 0.8)",
		fontSize     : "1.6rem"
	},
	midSection: {
		marginBottom: "1rem"
	},
	userFollowButton: {
		// padding       : "0rem 2.8rem",
		// height        : "30px",
		// borderRadius  : "10px",
		width: "100%"
		// boxShadow     : "none !important",
		// textTransform : "initial !important"
	},
	seeProfileButton: {
	},
	seeProfileText: {
	},
	descriptionText: {
		overflow        : "hidden",
		textOverflow    : "ellipsis",
		display         : "-webkit-box",
		WebkitLineClamp : "2",
		WebkitBoxOrient : "vertical",
		fontSize        : "1.5rem",
		color           : theme.other.colors.text.secondary
		// color           : "rgba(0, 0, 0, 0.8);"

	},
	crossContainer: {
		position  : "relative",
		left      : "44px",
		top       : "-8px",
		transform : "scale(0.5)"
	},
	cardFollowButton: {
		fontSize : "13px !important",
		color    : "#ffffff"
	},
	cardUnfollowButton: {
		fontSize : "1.5rem !important",
		// color    : "#fff !important"
		color    : `${ theme.other.colors.primaryBtn.clicked.text } !important`
	},
	userUnfollowButton: {
		width           : "100%",
		borderRadius    : "10px",
		backgroundColor : `${ theme.other.colors.primaryBtn.clicked.background } !important`,
		padding         : "0.5rem 0"
		// backgroundColor : "rgba(0, 0, 0, 0.25) !important",
		// "&:hover"       : {
		// border          : "none !important",
		// backgroundColor : `${ theme.other.colors.primaryBtn.clicked.background }!important`
		// }
	},
	headerWrapper: {
		display       : "flex",
		flexDirection : "column"
	},
	header: {
		display  : "flex",
		maxWidth : "300px"
	},
	headerDetails: {
		display    : "flex",
		alignItems : "center",
		fontSize   : "1.2rem",
		// color      : "#757575",
		color      : theme.other.colors.text.secondary,
		minWidth   : "147px"
	},
	dotDivider: {
		height          : "2px",
		width           : "2px",
		borderRadius    : "50%",
		// backgroundColor : "#757575"
		backgroundColor : theme.other.colors.border.primary

	},
	liveButton: {
		border          : theme.other.darkMode ? "none" : "1px solid white",
		left            : "7px !important",
		color           : "white",
		bottom          : "22px",
		position        : "relative",
		display         : "flex",
		justifyContent  : "center",
		alignItems      : "center",
		height          : "15px",
		padding         : "3px 0px 2px",
		maxWidth        : "28px",
		fontWeight      : "500",
		borderRadius    : "5px",
		backgroundImage : "linear-gradient(rgb(18, 107, 213), rgb(67, 154, 255))"
	},
	liveText: {
		letterSpacing : "0.5px",
		fontSize      : "8px"
	},
	nameContainer: {
		cursor      : "pointer",
		paddingLeft : "1rem"
	},
	buttonStyles: {
		margin      : "0.6rem 0",
		marginRight : "0.6rem"
	},
	gameImageStyles: {
		height       : "21px",
		width        : "21px",
		borderRadius : "5px"
	},
	chipContentStyles: {
		display    : "flex",
		alignItems : "center",
		padding    : "2px 0px"
	},
	gameNameStyles: {
		fontSize      : "14px",
		fontWeight    : "normal",
		// color         : "#000",
		color         : `${ theme.other.colors.text.primary }`,
		margin        : "0px",
		paddingLeft   : "5px",
		textTransform : "none"
	},
	followingGamesContainer: {
		display        : "flex",
		justifyContent : "flexStart",
		alignItems     : "center",
		flexWrap       : "wrap",
		marginBottom   : "1rem"
	},
	bottomSection: {
		display        : "flex",
		justifyContent : "space-between",
		alignItems     : "center",
		gap            : "1rem"
		// flexWrap       : "wrap"
	},
	verifiedUsers: {
		marginLeft: "0.8rem"
	},
	svgIcon: {
		width  : "12px",
		height : "12px"
	}
} ) );
