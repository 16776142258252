
import { createStyles } from "@mantine/emotion";
export default createStyles ( ( theme, props ) => {
	return {
		sidebarRoot: {
			height        : "calc( 100vh - 65px )",
			paddingRight  : "3rem",
			borderRight   : `0.75px solid ${ theme.other.colors.border.primary }`,
			display       : "flex",
			flexDirection : "column",
			gap           : "0rem",
			paddingTop    : "1rem"
		},
		navBar: {
			padding        : "0 2rem",
			borderRight    : theme.other.darkMode ? `0.75px solid ${ theme.other.colors.border.primary }` : "none",
			display        : "flex",
			justifyContent : "space-between",
			width          : "100%",
			position       : "fixed",
			bottom         : "0",
			zIndex         : 1002,
			borderTop      : `0.75px solid ${ theme.other.colors.border.primary }`,
			...( theme?.isMobile && { paddingBottom: "0.5rem" } )
		},
		menuItemContainerActive: {
			marginBottom : "1rem",
			position     : "relative",
			display      : "flex",
			alignItems   : "center",

			"&::before": {
				width                   : "0.45rem",
				content                 : "''",
				display                 : "block",
				height                  : "100%",
				position                : "absolute",
				borderTopRightRadius    : "1rem",
				borderBottomRightRadius : "1rem",
				backgroundImage         : "linear-gradient(to bottom, #00c5ff 0%, #0076ff 100%)"
			}

		},
		menuItemContainer: {
			// marginBottom : "1rem",
			display    : "flex",
			alignItems : "center",
			padding    : "1rem 0",
			cursor     : "pointer",
			...( theme?.isMobile && { paddingBottom: "0.25rem" } )
		},

		mobile: {
			position    : "relative",
			display     : "flex",
			alignItems  : "center",
			// backgroundColor : theme.paletteNew.darkModeDarkBlue50,
			padding     : "1rem 0",
			cursor      : "pointer",
			...( theme?.isMobile && { paddingBottom: "0.25rem" } ),
			"&::before" : {
				width                   : "100%",
				content                 : "''",
				display                 : "block",
				top                     : "0",
				height                  : "0.4rem",
				position                : "absolute",
				borderBottomLeftRadius  : "1rem",
				borderBottomRightRadius : "1rem",
				backgroundImage         : "linear-gradient(to bottom, #00c5ff 0%, #0076ff 100%)"
			}
		},
		menuItem: {
			// ...theme.svgIcons.size35,
			height   : theme.other.isMobile ? "2.5rem" : "3.5rem",
			width    : theme.other.isMobile ? "2.5rem" : "3.5rem",
			minWidth : theme.other.isMobile ? "2.5rem" : "3.5rem",
			margin   : theme.other.isMobile ? "0 1rem" : "0 0 0 1.5rem ",
			cursor   : "pointer"
		},
		menuName: {
			textOverflow : "ellipsis",
			whiteSpace   : "nowrap",
			marginLeft   : "1rem",
			fontWeight   : "normal",
			opacity      : props?.isSidebarMenuOpen ? "1" : "0",
			overflow     : "hidden",
			transition   : "0.2s"
		},
		mobileMenuName: {
			fontSize  : "1rem",
			textAlign : "center"
		}
	};
} );