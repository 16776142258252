import { useState }     from "react";
import { createPortal } from "react-dom";
import { usePopper }    from "react-popper";

import { storeEvent }  from "_analytics/index";
import Image           from "_components/atoms/image";
import Typography      from "_components/atoms/typography";
import UserInfoPopOver from "_components/molecules/userInfoPopOver";

import useStyles from "./styles";

const UserProfileCard = ( { data, isSidebarMenuOpen } ) => {
	const { classes }                               = useStyles ( { isSidebarMenuOpen } );
	const [ popperElement, setPopperElement ]       = useState ( null );
	const [ referenceElement, setReferenceElement ] = useState ( null );
	const [ hoverImage, setHoverImage ]             = useState ( false );
	let timerEnter, timerExit;

	const { styles, attributes } = usePopper ( referenceElement, popperElement, {
		placement : "bottom",
		modifiers : [
			{ name: "flip", enabled: false }
		  ]

	} );

	const handleHoverEnter = () => {
		clearTimeout ( timerExit );
		timerEnter = setTimeout ( () => {
			setHoverImage ( true );
		}, 500 );
	};

	const handleHoverExit = () => {
		clearTimeout ( timerEnter );
		timerExit = setTimeout ( () => {
			setHoverImage ( false );
		}, 500 );

	};

	const handleGAEvent = data => {
		storeEvent ( "side_nav", data.analyticsLabel, data.name );
	};

	return (
		<div
			className = { classes.avatarContainer }
		>
			<div
				ref          = { setReferenceElement }
				className    = { classes.imageContainer }
				onMouseEnter = { handleHoverEnter }
				onMouseLeave = { handleHoverExit }
			>
				<Image
					className = { classes.avatar }
					src       = { data.photo }
					type      = "mdpi"
				/>
			</div>

			{hoverImage && createPortal (
				<UserInfoPopOver
					ref                 = { setPopperElement }
					attributes          = { attributes }
					authorDetails       = { { id: data.id } }
					className           = { classes.popperStyles }
					handleHoverEnter    = { handleHoverEnter }
					handleHoverExit     = { handleHoverExit }
					style               = { styles.popper }
				/>,
					  document.querySelector ( "#user-info-popover" )
			)}

			<div
				className = { classes.nameContianer }
				style     = { {
					opacity: isSidebarMenuOpen ? "1" : "0"
				} }
			>
				<a href    = { `/profile/${ data.id }` }
					onClick   = { () => {
						handleGAEvent ( data );
					} }
					rel       = "noreferrer"
					target    = "_blank"
				>
					<Typography
						className = { classes.name }
						title     = { data.name }
					/>
				</a>

				{data?.count ? (
					<div className = { classes.liveCountContainer }>
						<div className = { classes.dot }></div>

						<Typography
							className = { classes.liveCount }
							color     = "secondary"
							title     = { data.count }
						/>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default UserProfileCard;