import React from "react";

import Presentation from "./presentation";

const component = ( { size, color, noMargin, style } ) => {
	return (
		<Presentation
			color    = { color }
			noMargin = { noMargin }
			size     = { size }
			style    = { style }
		/>
	);
};

export default component;
