import { createStyles } from "@mantine/emotion";

export default createStyles ( ( theme, props ) => {

	return {
		buttonStyles: {
			borderRadius    : "11px !important",
			border          : theme?.other?.darkMode ? "none" : `solid 1px ${ theme.other.colors.border.primary }`,
			padding         : "0.5rem 1.5rem !important",
			// marginRight     : "1.2rem",
			// marginBottom    : "1.2rem",
			cursor          : props?.cursor === "pointer" ? "pointer" : "default",
			backgroundColor : theme?.other?.darkMode ? theme.other.colors.background.tertiary : "transparent",

			"&:hover": {
				backgroundColor: theme?.other?.darkMode ? theme.other.colors.background.tertiary : "transparent"
			}

		},
		gameImage: {
			height       : "2rem",
			width        : "2rem",
			borderRadius : "0.5rem",
			marginRight  : "5px"
		},
		chipContentStyles: {
			display    : "flex",
			alignItems : "center"
		},
		gameName: {
			fontSize: "14px"
		// paddingLeft : "5px"
		},
		proButtonChip: {
			padding    : "0.1rem !important",
			overflow   : "hidden",
			cursor     : "pointer",
			position   : "relative",
			border     : "none",
			background : `${ theme.other.darkMode ? theme.other.paletteNew.darkModeInclinedBlack1 : theme.other.paletteNew.secondaryLightGrey }!important`,
			":after"   : {
				content         : "' '",
				position        : "absolute",
				left            : "50%",
				top             : "50%",
				height          : "140px",
				width           : "140px",
				backgroundImage : theme.other.paletteNew.proChipGradient,
				zIndex          : "0",
				transform       : "translate(-50%, -50%)",
				animation       : "rotateProChip 6s linear infinite",

				"@keyframes rotateProChip": {
					"0%": {
						transform: "translate(-50%, -50%) rotate(0deg)"
					},
					"100%": {
						transform: "translate(-50%, -50%) rotate(360deg)"
					}
				}
			}
		},
		proChipContentStyles: {
			position   : "relative",
			zIndex     : "1",
			background : `${ theme.other.darkMode ?
				theme.other.paletteNew.darkModeInclinedBlack1 :
				props.background === "white" ?
					theme.other.paletteNew.secondaryWhite :
					theme.other.paletteNew.secondaryLightGrey } !important`,
			padding      : "0.4rem 2rem",
			borderRadius : "1rem"
		}
	};
} );
