import React, { forwardRef } from "react";
import useSWR                from "swr";

import Toast              from "_common/toast";
import { getUserProfile } from "_services/api/user";

import Presentation from "./presentation";

const fetcher = async params => {
	const userProfileParams = {
		follow         : true,
		isFollowing    : true,
		country        : true,
		stats          : true,
		block          : true,
		teams          : false,
		sports         : false,
		pendingRewards : false,
		access         : true,
		types          : true,
		followingGames : true,
		ugcStats       : true,
		profileHandle  : true
	};

	const response = await getUserProfile ( params, userProfileParams );

	return response;
};

const component = forwardRef ( ( props, ref ) => {
	const { data, error } = useSWR ( { key: "user-profile-popover", userId: props?.authorDetails?.id }, fetcher );

	if ( error )
		return Toast.error ( error.displayMsg );

	return (
		<Presentation
			{ ...props }
			ref     = { ref }
			profile = { data }
		/>

	);
} );

component.displayName = "containerComponent";

export default React.memo ( component );
