import { storeEvent } from "_analytics/index";

import Presentation from "./presentation";

const UserInfoCard = props => {

	const handleGAEvent = type => {
		storeEvent ( "Profile", type, "side_nav" );
	};

	return (
		<Presentation { ...props }
			handleGAEvent = { handleGAEvent }
		/>
	);
};

export default UserInfoCard;