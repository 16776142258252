import { createStyles } from "@mantine/emotion";

export default createStyles ( ( theme, props ) => {
	return {
		loaderContainer: {
			display        : "flex",
			justifyContent : "center",
			alignItems     : "center",
			margin         : props?.noMargin ? "0" : "1rem"
		},
		circularLoader: {
			margin : props?.size ? "0" : "1.5rem",
			color  : props?.color ? props?.color : theme.other.paletteNew.primaryDarkBlue
		}
	};
} );

