import { createStyles } from "@mantine/emotion";

import { rooterMediaQuery } from "_styles/theme/mediaQuery";

export default createStyles ( ( theme, props ) => ( {
	avatarContainer: {
		// transition     : "0.2s",
		display        : "flex",
		alignItems     : "center",
		justifyContent : "center",
		padding        : "0px 2rem 1.5rem",

		[rooterMediaQuery.tablet]: {
			padding: "0px 1rem 1.5rem"
		}

	},
	avatar: {
		height       : "3.5rem",
		width        : "3.5rem",
		minWidth     : "3.5rem",
		cursor       : "pointer",
		borderRadius : "100%",
		"&:hover"    : {
			outline: `2px solid ${ theme.other.paletteNew.primaryDarkBlue }`
		},

		[rooterMediaQuery.tablet]: {
			height   : "2.8rem",
			width    : "2.8rem",
			minWidth : "2.8rem"
		}
	},
	nameContianer: {
		marginLeft : props?.isSidebarMenuOpen ? "1rem" : "0",
		width      : props?.isSidebarMenuOpen ? "75%" : "1%",
		transition : "0.2s"

	},
	name: {
		textOverflow : "ellipsis",
		whiteSpace   : "nowrap",
		fontSize     : "1.6rem",
		fontWeight   : "500",
		overflow     : "hidden",
		transition   : "0.2s",

		"&:hover": {
			textDecoration: "underline"
		},

		[rooterMediaQuery.tablet]: {
			fontSize: "1.4rem"

		}
	},
	liveCountContainer: {
		display    : "flex",
		alignItems : "baseline"
	},
	dot: {
		height          : "0.875rem",
		width           : "0.875rem",
		backgroundImage : "linear-gradient(to bottom, #ff177e 0%, #ff6a38 100%)",
		borderRadius    : "1rem",
		marginRight     : "0.5rem"
	},
	liveCount: {
		textOverflow : "ellipsis",
		whiteSpace   : "nowrap",
		fontSize     : "1.25rem",
		overflow     : "hidden"
	},
	popperStyles: {
		width           : "400px",
		height          : "auto",
		borderRadius    : "10px",
		backgroundColor : theme.other.colors.background.primary,
		boxShadow       : theme.other.colors.boxShadow.primary,
		padding         : "2rem",
		left            : "20px !important",
		// top             : "-19px !important",
		zIndex          : 10000
	},
	imageContainer: {
		display: "flex"
	}

} ) );

