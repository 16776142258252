import Link               from "next/link";
import React              from "react";
import { useTranslation } from "react-i18next";

import { storeEvent }                    from "_analytics/index";
import { shortenNumber }                 from "_common/utils";
import { PrimaryButton, TertiaryButton } from "_components/atoms/button";
import Icon                              from "_components/atoms/icon";
import Image                             from "_components/atoms/image";
import Loader                            from "_components/atoms/loader";
import Typography                        from "_components/atoms/typography";
import Chip                              from "_components/molecules/chip";
import FollowUser                        from "_components/molecules/followUser";
import useStyles                         from "_styles/molecules/userInfoPopOver";

const component = props => {
	const { classes }                = useStyles ( undefined, { name: "styles" } );
	const { profile, handleGAEvent } = props;
	const { t }                      = useTranslation ();

	const {
		id,
		followingGames,
		isCeleb,
		name,
		photo,
		shortBio,
		ugcStats,
		follow
	} = profile || {};

	return (
		<>
			{!profile ? <Loader /> : (
				<div>
					<div
						className = { classes.topSection }
						onClick   = { () => {
							storeEvent ( "Profile", "View", "Popular_Creators" );
						} }
					>

						<Link
							passHref
							href = { `/profile/${ id }` }
						>
							<div className = { classes.avatarContainer }>

								<div className = { classes.avatar }>
									<Image
										className = { classes.img }
										src       = { photo }
									/>

									{/* {isLive && (
                                    <div className = { classes.liveButton }>
                                        <span className = { classes.liveText }>LIVE</span>
                                    </div>
                                )} */}
								</div>

							</div>
						</Link>

						<div className = { classes.nameContainer }>
							<Link
								passHref
								href = { `/profile/${ id }` }
							>
								<div className = { classes.headerWrapper }>
									<div className = { classes.header }>
										<Typography
											className = { `fontBold ${ classes.cardHeader }` }
											// color     = "primary"
											title     = { name }
											variant   = "body1"
										/>

										{isCeleb ? (
											<div className = { classes.verifiedUsers }>
												<Icon sameInBothTheme
													className = { classes.svgIcon }
													name      = "verifiedProfile"
												/>
											</div>
										) : null}

									</div>

									<div className = { classes.headerDetails }>
										<div>
											{" "}

											{shortenNumber ( follow?.followers )}

											{" "}
											Followers  &nbsp;

											{" "}
										</div>

										{(
											<>
												<div className = { classes.dotDivider }></div>

												<div>
													{" "}
                          &nbsp;
													{" "}

													{ugcStats?.postCount}

													{" "}
													Posts
												</div>
											</>
										)}
									</div>
								</div>
							</Link>
						</div>
					</div>

					{shortBio ? (
						<div className = { classes.midSection }>
							<Typography className = { classes.descriptionText }
								color                = "primary"
								title                = { shortBio }
								variant              = "body2"
							/>
						</div>
					) : null}

					{followingGames ? (
						<div className = { classes.followingGamesContainer }>
							{followingGames?.map ( game => (
								<Chip
									key          = { game?.id }
									buttonStyles = { classes.buttonStyles }
									id           = { game?.id }
									image        = { game?.image }
									name         = { game?.name }
								/>
							) )}
						</div>
					) : null}

					<div className = { classes.bottomSection }>
						<FollowUser
							followComponent   = { (
								<PrimaryButton
									className = { classes.userFollowButton }
									size      = "small"
									title     = { `+ ${ t ( "action_follow" ) }` }
								/>

							) }
							from              = "userInfoPopOver"
							handleGAEvent     = { handleGAEvent }
							unfollowComponent = { (
								<PrimaryButton
									active
									fullWidth
									size  = "small"
									title = { "Following" }

								/>
							) }
							userId            = { id }
							userName          = { name }
						/>

						<div style = { { flexGrow: "1" } }>

							<Link href = { `/profile/${ id }` }>
								<TertiaryButton
									buttonTextClassName = { classes.seeProfileText }
									className           = { classes.seeProfileButton }
									fullWidth           = { true }
									size                = "small"
									title               = "See Full Profile"
								/>
							</Link>
						</div>

					</div>
				</div>
			)}
		</>
	);
};

export default component;
